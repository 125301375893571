import React, { FC, lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Layout from 'layout/Layout';
import Container from 'layout/Container';
import SearchNoResults from 'components/SearchNoResults';
import AlgoliaSearchBox from 'components/AlgoliaSearchBox';
import Title from 'common/Title';

import { getLocationQueryStringParam } from 'utils/browser';
import { ALGOLIA_URL_PARAMS, IEntitiesGroupsData } from 'utils/algoliaFilters';

import { IPropsSearchResultPage } from './models';

import './SearchResultPage.scss';
import './ShadowSearchResultPage.scss';

const SearchResultsLoadable = lazy(() => import('components/SearchResults'));
const renderLoader = () => null;

const activeColor = '#FDBD21';

const SearchResultPage: FC<IPropsSearchResultPage> = ({
  data: {
    pageData,
    featuredProductsByLink,
    featuredProductsByTag,
    featuredArticlesByLink,
    featuredArticlesByTag,
  },
  pageContext: { lang, link },
}) => {
  const [entitiesGroups, setEntitiesGroups] = useState<IEntitiesGroupsData | null>(null);
  const [resultsNumber, setResultsNumber] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [queryToDisplay, setQueryToDisplay] = useState<string>('');

  const { seo, defaultCompositions, featuredArticlesList } = pageData;
  const queryUrlParam = getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch, 'string');

  useEffect(() => {
    setQueryToDisplay(queryUrlParam);
  }, [queryUrlParam]);

  const saveEntitiesGroups = useCallback((data: IEntitiesGroupsData | null) => {
    setEntitiesGroups(data);
  }, []);

  const saveResultsNumber = useCallback((value: number) => {
    setResultsNumber(value);
  }, []);

  const handleLoadingStatus = useCallback((value: boolean) => {
    setLoading(value);
  }, []);

  const searchNoResultsView = (
    <SearchNoResults
      defaultCompositions={defaultCompositions}
      featuredArticlesList={featuredArticlesList}
      featuredArticlesByLink={featuredArticlesByLink}
      featuredArticlesByTag={featuredArticlesByTag}
      dir={defaultCompositions.siteSettings.dir[0]}
      ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
      ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
      featuredProducts={pageData.featuredProductsList}
      featuredProductsByLink={featuredProductsByLink.nodes}
      featuredProductsByTag={featuredProductsByTag.nodes}
      activeColor={activeColor}
      usefulLinks={pageData.usefulLinks}
    />
  );

  return (
    <Layout
      className="search-result-page-layout"
      seo={seo}
      defaultCompositions={defaultCompositions}
      dynamicStructuredSchemaValues={{ pageUrl: link }}
    >
      <div data-test="SearchResultPage" className="search-result-page">
        <Container>
          <Title
            dataTestAttr="SearchResultsTitle"
            Tag="h1"
            className={classNames('search-result-page__title', {
              loading: isLoading,
            })}
          >
            <span>{entitiesGroups ? pageData.title : pageData.titleNoResults}</span>
            {queryToDisplay ? (
              <span className="search-result-page__text">{queryToDisplay}</span>
            ) : null}
            <span className="search-result-page__count">{resultsNumber}</span>
          </Title>
        </Container>
        <AlgoliaSearchBox
          indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-search`}
          title={pageData.searchInputTitle}
          searchIconFormAriaLabel={pageData.searchIconAriaLabel}
          resetIconFormAriaLabel={pageData.resetIconAriaLabel}
          lang={lang}
          saveEntitiesGroups={saveEntitiesGroups}
          isResultExist={Boolean(entitiesGroups)}
          saveResultsNumber={saveResultsNumber}
          handleLoadingStatus={handleLoadingStatus}
          isLoading={isLoading}
          searchTitle={defaultCompositions.siteSettings.searchTitle}
        />

        {queryUrlParam ? (
          !isLoading ? (
            entitiesGroups ? (
              <Suspense fallback={renderLoader()}>
                <SearchResultsLoadable
                  entitiesGroups={entitiesGroups}
                  searchCategories={pageData.searchCategories}
                />
              </Suspense>
            ) : (
              searchNoResultsView
            )
          ) : null
        ) : (
          searchNoResultsView
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query (
    $link: String!
    $lang: String!
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $selectedProductsLinks: [String]
    $selectedProductTag: Int
  ) {
    pageData: searchResult(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      titleNoResults
      searchInputTitle
      searchIconAriaLabel
      resetIconAriaLabel
      searchCategories {
        ...FragmentEntityTab
      }
      usefulLinks {
        properties {
          link {
            name
            url
          }
          title
        }
      }
      featuredArticlesList {
        ...FragmentFeaturedArticles
      }
      featuredProductsList {
        ...FragmentFeaturedProducts
      }
    }
    featuredArticlesByLink: allArticle(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesByTag: allArticle(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredProductsByLink: allProduct(
      filter: { link: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTag: allProduct(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
  }
`;

export default SearchResultPage;
