import { ALGOLIA_SEARCH_FIELDS, ALGOLIA_SEARCH_ENTITY_TYPES } from 'utils/algolia/search/constants';
import { ALGOLIA_URL_PARAMS, IEntitiesGroupsData } from 'utils/algoliaFilters';
import {
  generateLocationQueryString,
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  setUrl,
} from 'utils/browser';

const getDefaultFiltersParams = (lang: string, additionalFilterParams?: string): string =>
  `lang:${lang}${additionalFilterParams || ''}`;

const getParsedEntities = (itemsToRender: any[]): IEntitiesGroupsData | null => {
  if (!itemsToRender.length) {
    return null;
  }

  const itemsToSet = {};
  itemsToRender.forEach((item) => {
    const entityName = item[ALGOLIA_SEARCH_FIELDS.entity];
    if (!entityName) {
      return;
    }
    if (!itemsToSet[entityName]) {
      itemsToSet[entityName] = [];
    }

    itemsToSet[entityName].push(item);
  });

  return itemsToSet;
};

const getSavedQueryToSet = (): string | null =>
  getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch, 'string');

const setSearchUrlParam = (queryToSave: string): void => {
  let url: string | null = generateLocationQueryString(ALGOLIA_URL_PARAMS.querySearch, queryToSave);

  if (!url || !queryToSave) {
    url = getRemovedParamFromLocationQueryString(ALGOLIA_URL_PARAMS.querySearch);
  }

  setUrl(url || '');
};

const Helpers = {
  getDefaultFiltersParams,
  getParsedEntities,
  getSavedQueryToSet,
  setSearchUrlParam,
};

export default Helpers;
