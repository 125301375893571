import React, { FC, ReactElement, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import RelatedCarousel from 'components/RelatedCarousel';
import Helpers from 'components/FeaturedArticles/helpers';
import RelatedArticleCard from 'components/RelatedArticleCard';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { isNotEmptyArray } from 'utils/array';

import { IPropsFeatureArticles } from './models';

import './FeaturedArticles.scss';
import './ShadowFeaturedArticles.scss';

const FeaturedArticles: FC<IPropsFeatureArticles> = ({
  featuredArticlesList,
  featuredArticlesByLink,
  featuredArticlesByTag,
  ariaLabelPrev,
  ariaLabelNext,
  dir,
}): ReactElement | null => {
  const [slidesPerView, setSlidesPerView] = useState<number | null>(null);
  const { isDesktop, windowWidth } = useScreenRecognition();

  useEffect(() => {
    if (!windowWidth) {
      return;
    }

    setSlidesPerView(Helpers.getSlidesPerView(windowWidth));
  }, [windowWidth]);

  const {
    relatedArticlesSectionTitle,
    ctaButtonAriaLabel,
    ctaButtonLink,
    ctaButtonText,
    selectRelatedArticlesBy,
    showMoreButtonAriaLabel,
  } = featuredArticlesList[0].properties;

  const itemsToRender =
    selectRelatedArticlesBy?.[0] === 'Manually' ? featuredArticlesByLink : featuredArticlesByTag;

  return (
    <div className="featured-articles" data-test="FeaturedArticles">
      <div className="featured-articles__wrapper">
        <DangerouslySetInnerHtml
          className="featured-articles__title"
          html={relatedArticlesSectionTitle}
        />
        {slidesPerView ? (
          <RelatedCarousel
            slidesPerView={slidesPerView}
            ariaLabelPrev={ariaLabelPrev}
            ariaLabelNext={ariaLabelNext}
            isDesktop={Boolean(isDesktop)}
            dir={dir}
            isShowPagination={itemsToRender.length > slidesPerView}
            isShowNavigation={itemsToRender.length > slidesPerView}
            loop={slidesPerView < itemsToRender.length}
          >
            {itemsToRender.map((article) => (
              <RelatedArticleCard
                articleIntroduction={article.articleHeroBanner[0].properties.articleIntroduction}
                articleShortTitle={article.articleHeroBanner[0].properties.articleShortTitle}
                mainArticleImage={article.articleHeroBanner[0].properties.image}
                mobileArticleImage={article.articleHeroBanner[0].properties.mobileImage}
                mainArticleImageAltText={
                  article.articleHeroBanner[0].properties.heroBannerImageAltText
                }
                link={article.link}
                tags={article.tags}
                key={article.link}
                showMoreButtonAriaLabel={showMoreButtonAriaLabel}
              />
            ))}
          </RelatedCarousel>
        ) : null}
        {isNotEmptyArray(ctaButtonLink) ? (
          <div className="related-articles__cta">
            <Button ariaLabel={ctaButtonAriaLabel} link={ctaButtonLink[0].url}>
              {ctaButtonText}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentFeaturedArticles on TFeaturedArticles {
    properties {
      relatedArticlesSectionTitle
      ctaButtonText
      ctaButtonLink {
        url
        name
      }
      ctaButtonAriaLabel
      selectRelatedArticlesBy
      showMoreButtonAriaLabel
    }
  }
  fragment FragmentArticleCardRelated on Article {
    id
    link
    articleHeroBanner {
      properties {
        title
        showTitleMobile
        image {
          ...FragmentGatsbyImage
        }
        mobileImage: image {
          gatsbyImage {
            childImageSharp {
              fluid(maxHeight: 200, fit: CONTAIN, quality: 30) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        articleShortTitle
        articleIntroduction
        heroBannerImageAltText
      }
    }
    tags {
      ...FragmentArticleCategoryTag
    }
  }
  fragment FragmentArticleSymptomsCardRelated on ArticleSymptoms {
    id
    link
    articleHeroBanner {
      properties {
        title
        showTitleMobile
        image {
          ...FragmentGatsbyImage
        }
        mobileImage: image {
          gatsbyImage {
            childImageSharp {
              fluid(maxHeight: 200, fit: CONTAIN, quality: 30) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        articleShortTitle
        articleIntroduction
        heroBannerImageAltText
      }
    }
    tags {
      ...FragmentArticleCategoryTag
    }
  }
  fragment FragmentArticleAboutStrepsilsCardRelated on ArticleAboutStrepsils {
    id
    link
    articleHeroBanner {
      properties {
        title
        showTitleMobile
        image {
          ...FragmentGatsbyImage
        }
        mobileImage: image {
          gatsbyImage {
            childImageSharp {
              fluid(maxHeight: 200, fit: CONTAIN, quality: 30) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        articleShortTitle
        articleIntroduction
        heroBannerImageAltText
      }
    }
    tags {
      ...FragmentArticleCategoryTag
    }
  }
`;

export default FeaturedArticles;
